/* Generic */
@font-face {
  font-family: gyre;
  src: url(./css/font-style/gyre.otf);
}

@font-face {
  font-family: junicode;
  src: url(./css/font-style/Junicode.ttf);
}

@font-face {
  font-family: vinque;
  src: url(./css/font-style/Vinque.otf);
}


* {
  font-family: junicode;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.fredoka {
  font-family: Fredoka One;
}

.medium-size {
  font-size: 2.0rem;
}

.small-size {
  font-size: 1.0rem;
}

a {
  font-weight: bold;
}

.navbar a {
  font-family: vinque;
  padding: 5px;
}

table {
  border-color: gray;
}

th,
td {
  border: 1px solid;
  padding: 8px;
}

thead {
  border-bottom: 3px solid;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.display-flex {
  display: flex;
}

.flex {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.p10 {
  padding: 10px
}

.display-flex-spaced {
  display: flex;
  justify-content: space-between;
}

.align-left {
  text-align: start;
  place-self: start;
}

.input-medium {
  width: 300px;
}

.ver-center {
  place-items: center;
}

.hor-center {
  place-self: center;
}

@media screen and (min-width: 992px) {
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-image: url('img/shadow_figure_banner.png');
    /* Replace with the path to your image */
    background-size: contain;
    /* Cover the entire header */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    background-color: black;
    -webkit-filter: drop-shadow(-5px -5px 5px #000);
    filter: drop-shadow(-5px -5px 5px #000);
  }
}

@media screen and (max-width: 992px) {
  .App-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    z-index: 1000;
  }

  .navbar {
    margin-top: 0px !important;
  }

  .nav-item {
    border-right: none !important;
  }

  .nav-item-first {
    border-left: none !important;
  }

  .restrained-section {
    width: fit-content !important;
  }

  .overflowOverlay {
    overflow: overlay;
  }
}

.text-red {
  color: red;
}

/* Navigation */
.menu-spacer {
  flex-grow: inherit;
}

.App-header .navbar {
  width: 60%;
  margin-top: 180px;
}

.App-header .navbar-collapse {
  margin-left: 20px;
  margin-right: 20px;
}

.App-header .nav-item {
  padding-left: 30px;
  padding-right: 30px;
  border-right: solid 1px gray;
}

.App-header .nav-item-first {
  border-left: solid 1px gray;
}

.App-header .nav-item-lang {
  padding-right: 10px;
  padding-bottom: 0px !important;
}

.App-header a.nav-link {
  font-size: 24px;
  color: orange;
}

.App-header a.nav-link:focus,
.App-header a.nav-link:hover {
  font-size: 24px;
  color: darkorange;
}

.App-header .navbar-brand,
a.navbar-brand:focus,
.App-header a.navbar-brand:hover {
  font-size: 24px;
  color: darkorange;
}

#loginButton a {
  font-size: 24px;
}

/* Login */
.login-modal .btn-outline-primary,
.signup-modal .btn-outline-primary {
  height: 50px;
  width: 200px;
}

.signup-modal {
  width: 50%;
}

.nav-item-lang {
  border: 0px;
}

.nav-item-lang select {
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  border-radius: var(--bs-btn-border-radius);
}

.recaptcha {
  margin-bottom: 40px;
}

.login-message {
  margin-top: 40px;
}

.page_content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.restrained-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hor-flex {
  display: flex;
  flex-direction: row;
}

.ver-flex {
  display: flex;
  flex-direction: column;
}

.fill {
  width: 100%;
}

form {
  width: 100%;
}

.form-group {
  text-align: start;
}

.space-small {
  margin: 5px;
}

.space-medium {
  margin: 15px;
}

.display-field {
  width: 100%;
  text-align: start;
  display: flex;
  margin: 5px;
}

.display-field label {
  font-weight: bold;
  min-width: 120px;
  text-align: end;
  padding-right: 10px;
}

.form-group label {
  font-weight: bold;
}

.larp-display,
.character-display,
.user-display {
  border: solid 1px gray;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  place-items: center;
}

.user-display {
  margin-top: 5px !important;
}

.character-display {
  margin-left: 10%;
  margin-top: 5px;
  width: 90%;
}

.larp-display span {
  cursor: pointer;
}

.character-display span {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.full-display {
  width: 100%;
}

.add-character-button {
  float: inline-end;
}

#loginButton {
  width: auto;
}

.pin {
  margin-top: -15px;
  /** must be 50% of height */
  height: 30px;
}

.larp-img {
  height: 200px;
}

.larp-img-small {
  height: 60px;
}

.my-larp-display-name {
  margin-left: 10px;
  margin-inline-end: auto;
}

.larp-content {
  width: fit-content;
  min-width: 300px;
}

.admin-list-display {
  border: solid 1px gray;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  place-items: center;
}

.my-larp-display-name {
  cursor: pointer;
}

.input-small {
  width: 60px;
}

.point-display {
  place-items: center;
}

.point-display label,
.cat-display label {
  font-weight: bold;
}

.cat-display label {
  margin-right: 5px;
  ;
}

.cat-display {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.cat-label {
  flex: 1;
  text-align: right;
  padding-right: 20px;
  place-self: center;
}

.cat-input {
  flex: 1;
  text-align: left;
}

.cat-delete-button {
  place-self: center;
  margin-top: 0px;
}

.remove_cat_button {
  width: 200px;
  place-self: center;
}

.cat-sub-cat {
  place-items: center;
}

.sub-cat-label {
  padding-top: 5px;
  margin-bottom: auto;
}

.middle-split {
  width: 100%;
}

.middle-split label {
  flex: 1;
  text-align: end;
  margin-right: 10px;
}

.table-btn {
  padding-top: 0px;
  padding-bottom: 0px;
}

.edit-feat-modal .modal-content {
  width: fit-content;
}

.feat-title {
  margin-left: 20px;
  margin-right: 20px;
}

.content-section {
  padding: 20px;
  width: 80%;
}

.category-display {
  border-top: solid 1px lightgray;
  display: flex;
  flex-direction: column;
}

.category-choice {
  text-align: -webkit-center;
  display: flex;
}

.category-choice select {
  width: 200px;
}

.feat-display .feat-field {
  margin-right: 10px;
  margin-left: 10px;
}

.feat-display label {
  font-weight: bold;
  margin-right: 5px;
}

.feat-display {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.feat-display.status-available {
  border: solid 1px lightsalmon;
  cursor: pointer;
}

.feat-display.status-display {
  border: solid 1px orange;
  cursor: pointer;
}

.feat-display.status-acquired-select {
  border: solid 1px black;
  cursor: pointer;
}

.feat-display.status-available label,
.feat-display.status-acquired {
  cursor: pointer;
}

.feat-display.status-confirmed {
  border: solid 1px lightgreen;
}

.feat-display.status-available-hidden,
.dm_select {
  border: solid 1px darkred;
}

.feat-display.status-acquired {
  border: solid 1px lightblue;
}

.feat-display.status-available:hover,
.feat-display.status-acquired:hover {
  background-color: lightgray;
}

.hidden-true {
  color: darkred
}

.left-side-bar {
  padding: 20px;
  width: 250px;
  margin-bottom: auto;
}

.right-side-bar {
  place-self: baseline;
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 20px;
  width: 340px;
}

.right-side-bar-big {
  width: 540px;
  place-items: start;
}

.right-side-bar svg {
  align-self: center;
}

.align-center {
  align-self: center;
}

.manage-section {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-bottom: 20px;
}

.sticky-info {
  position: fixed;
  background: white;
  left: 20px;
  border: solid 1px lightgray;
  padding: 10px;
  border-radius: 10px;
}

.sticky-local {
  position: fixed;
  z-index: 5000;
  color: red;
  background: white;
  border: solid 1px black;
  padding: 10px;
  border-radius: 20px;
}

.sticky-close {
  position: fixed;
  background: white;
  left: -50px;
  border: solid 1px lightgray;
  padding: 10px;
  border-radius: 10px;
}

.left-title {
  text-align: start;
  place-self: start;
}

.align-center {
  justify-content: center;
}

.selected-true td {
  background-color: lightblue !important;
}

.add-pts-field {
  align-items: center;
}

.feat-flex {
  display: flex;
  place-items: center;
}

.feat-flex-vert {
  display: flex;
  place-items: center;
  flex-direction: column;
}

.display-dms {
  display: block;
  margin-left: 10px;
}

.breadcrumb-section {
  place-self: start;
}

.header-restrained-section {
  width: 80%;
  justify-content: center;
}

.input-visibility-button {
  position: absolute;
  right: 60px;
  padding-top: 10px;
}

.event-form {
  display: flex;
  flex-direction: column;
}

.event-img {
  height: 250px;
  width: fit-content;
  place-self: center;
}

.edit-article-modal .modal-dialog {
  display: table;
}

.edit-article-modal .modal-content {
  width: 900px;
  display: table;
}

.errorMessage {
  color: darkred;
}

.article-display a {
  color: black;
}

.article-text {
  white-space: pre-line;
  text-align: left;
}

.display-modification {
  place-self: start;
}

.feat-form-section {
  width: 700px;
}

.max-select-display {
  text-wrap: nowrap;
  margin-right: 0.5rem;
  font-size: 30px;
  font-weight: 700;
}

.background-section {
  width: 100%;
  text-align: start;
}

.background-section label {
  font-weight: bold;
  padding-right: 10px;
}

.calendar {
  margin: 20px;
}

.calendar a {
  color: black;
  text-decoration: none;
}

.MyLarp-active .fc-mylarps-button {
  background-color: orange;
  color: black;
}

.AllLarp-active .fc-allLarps-button {
  background-color: orange;
  color: black;
}

.btn-orange {
  background-color: lightsalmon;
  color: black;
}

.btn-green {
  background-color: lightgreen;
  color: black;
}

.btn-blue {
  background-color: lightblue;
  color: black;
}

.bold {
  font-weight: bold;
}
