.tab-titles li {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: solid 1px black;
    border-radius: 10px;
}

.tab-titles li.active {
    background-color: orange;
}

.tab-titles li.sub-active {
    background-color: burlywood;
}

.tab-titles li.hidden-cat {
    background-color: lightcoral;
}

.tab-titles li.hidden-sub-active {
    background-color: orangered;
}

.tab-titles {
    margin-top: 20px;
}

.back-home {
    vertical-align: text-top;
    margin-right: 5px;
}

.selected-true.sub_status-present td {
    background-color: lightsalmon !important;
}

.selected-true.sub_status-paid td {
    background-color: lightgreen !important;
}
