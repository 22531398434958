.event-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    padding: 15px;
    margin: 20px;
}

.event-small {
    flex-direction: column;
    width: 300px;
}

.event-small .event-image {
    width: 400px;
    place-self: center;
}

.event-image {
    width: 300px;
    place-self: center;
}

.event-image img {
    height: 150px;
    object-fit: cover;
}

.event-details {
    flex-grow: 1;
}

.event-description {
    color: #666;
}

.event-datetime {
    font-style: italic;
}

.event-actions {
    min-width: fit-content;
}

.event-closed {
    background-color: lightgray;
}
