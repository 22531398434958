.mapSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; 
    margin-top: 10px;
    width: 80%;
    margin: 0 auto; 
  }
  
  .mapSearch  > div:first-child{
    position: relative;
    width: 100%; 
    margin-top: 20px;
  }
  
  .larpSearch {
    border-radius: 16px;
    position: absolute;
    width: 25%;
    height: 480px; 
    background-color: whitesmoke;
    padding: 10px;
    box-sizing: border-box;
    z-index: 2;
    left: 60px;
    top: 10px;
    overflow: auto;  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .larpSearch::-webkit-scrollbar {
    display: none;
  }
  
  .map {
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 1;
  }
  
  .larpSearch h1 {
    margin-top: 0px;
    font-size: 30px;
  }
  
  .larpSearch input {
    border-radius: 16px;
    height: 35px;
    padding-left: 22px;
  }

  /*react-select*/
  .css-b62m3t-container{ 
    margin: 5px;
    width: 65%;
  }

  .sixDigitNumber {
    width: 100px;
  }
  
  .threeDigitNumber {
    width: 80px;
  }

  .twoDigitNumber {
    width: 70px;
  }

  .sameLineAligned{
    display: flex;
    align-items: center;
  }
  
  .mapSearch:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .larpResult {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  
  .larpResult img {
    width: 30%; 
    margin-right: 10px; 
  }
  
  .larpResult p{
    font-size: smaller;
  }

  button{
    border-radius: 16px;
    height: 35px;
    padding: 0px 10px;
    margin: 10px;
  }

  .extra-filters {
    overflow: hidden;
    opacity: 0; 
    height: 0; 
    transition: height 1s ease 0.1s, opacity 1s ease;
  }
  
  .extra-filters.open {
    overflow: visible;
    opacity: 1; 
    transition: opacity 1s ease 0.1s, height 1s ease; 
  }
  
  @media screen and (max-width: 600px) {
    .mapSearch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 250px;
    }
  
    .larpSearch {
      width: 100%;
      position: relative;
      left: auto;
    }
  
    .map {
      margin-top: 10px;
    }
  }

  @supports (-moz-appearance: none) {
    .larpResult img {
        width: 100px; 
    }
  }